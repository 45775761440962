<template>
  <div class="hm-rating">
    <div>
      <span
        v-for="(n, $index) in starsRatingPercentages"
        :key="$index"
        class="rating"
      >
        <i
          class="fa selected-icon c-brand-color"
          :style="{ width: `${n}%` }"
          :class="selectedIconClass"
        ></i>
        <i
          class="fa unselected-icon c-brand-color"
          :class="unselectedIconClass"
        ></i>
      </span>
    </div>
    <small class="mx-1" v-if="showRatingNumber">{{ rating }}</small>
    <small v-if="numberOfReviews">
      ({{
        $t('PRODUCTS.LABELS.REVIEWS_VARIANT', {
          cnt: numberOfReviews,
        }, numberOfReviews)
      }})
    </small>
  </div>
</template>

<script>
export default {
  name: 'HMRating',
  props: {
    rating: {
      type: Number,
      default: 0,
    },
    showRatingNumber: {
      type: Boolean,
      default: false,
    },
    iconType: {
      type: String,
      default: 'stars',
    },
    numberOfReviews: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    selectedIconClass() {
      const icons = {
        stars: 'fa-star',
        hearts: 'fa-heart',
        circles: 'fa-circle',
      };
      return icons[this.iconType];
    },
    unselectedIconClass() {
      const icons = {
        stars: 'fa-star-o',
        hearts: 'fa-heart-o',
        circles: 'fa-circle-o',
      };
      return icons[this.iconType];
    },
    // Calculate the percentage for each star rating
    starsRatingPercentages() {
      const percentages = [];
      for (let i = 1; i <= 5; i++) {
        const percent = Math.max(0, Math.min(100, (this.rating - i + 1) * 100));
        percentages.push(percent);
      }

      return percentages;
    },
  },
};
</script>

<style lang="scss" scoped>
.hm-rating {
  display: flex;
  align-items: center;
}

.rating {
  position: relative;
  display: inline-grid;
  + .rating {
    margin-left: 2px;
  }
}

.rating .selected-icon {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}
</style>
